// project import
import Routes from './routes';
import ThemeCustomization from './themes';
import Locales from './components/Locales';
import RTLLayout from './components/RTLLayout';
import ScrollTop from './components/ScrollTop';
import Snackbar from './components/@extended/Snackbar';
import { FirebaseProvider as AuthProvider } from './contexts/FirebaseContext';

import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { sliceKey as AuthSliceKey, reducer as AuthReducer } from 'sections/auth/redux/slice';
import { AuthRepoSaga } from 'sections/auth/redux/saga';

import { sliceKey as PropertySliceKey, reducer as PropertyReducer } from 'pages/PropertyListing/slice';
import { PropertyRepoSaga } from 'pages/PropertyListing/saga';

import { sliceKey as ServicesSliceKey, reducer as ServicesReducer } from 'pages/ServiceCreation/slice';
import { ServicesRepoSaga } from 'pages/ServiceCreation/saga';

import { sliceKey as SupportSliceKey, reducer as SupportReducer } from 'pages/Support/slice';
import { SupportRepoSaga } from 'pages/Support/saga';

import { sliceKey as ImageUploadSliceKey, reducer as ImageUploadReducer } from 'pages/ImagesUpload/slice';
import { ImageUploadRepoSaga } from 'pages/ImagesUpload/saga';

import { sliceKey as PlotForSalesSliceKey, reducer as PlotForSalesReducer } from 'pages/PlotForSale/slice';

import { PlotForSaleRepoSaga } from 'pages/PlotForSale/saga';

import { sliceKey as ConstructionLeadSliceKey, reducer as ConstructionLeadReducer } from 'pages/ConstructionLead/slice';
import { ConstructionLeadRepoSaga } from 'pages/ConstructionLead/saga';

import { sliceKey as ServiceOrderSliceKey, reducer as ServiceOrderReducer } from 'pages/ServiceOrder/slice';
import { ServiceOrderRepoSaga } from 'pages/ServiceOrder/saga';

import { sliceKey as BlogSliceKey, reducer as BlogReducer } from 'pages/Blog/slice';
import { BlogRepoSaga } from 'pages/Blog/saga';

import { sliceKey as PlotToBuySliceKey, reducer as PlotToBuyReducer } from 'pages/PlotToBuy/slice';
import { PlotToBuyRepoSaga } from 'pages/PlotToBuy/saga';

import { sliceKey as PropertyLeadsSliceKey, reducer as PropertyLeadsReducer } from 'pages/PropertyLeads/slice';
import { PropertyLeadsRepoSaga } from 'pages/PropertyLeads/saga';

import { sliceKey as DashboardSliceKey, reducer as DashboardReducer } from 'pages/dashboard/slice';
import { DashboardRepoSaga } from 'pages/dashboard/saga';

const App = () => {
  useInjectReducer({
    key: AuthSliceKey,
    reducer: AuthReducer
  });
  useInjectSaga({ key: AuthSliceKey, saga: AuthRepoSaga });
  //*********** */
  useInjectReducer({
    key: PropertySliceKey,
    reducer: PropertyReducer
  });
  useInjectSaga({ key: PropertySliceKey, saga: PropertyRepoSaga });
  //************** */
  //*********** */
  useInjectReducer({
    key: ServicesSliceKey,
    reducer: ServicesReducer
  });
  useInjectSaga({ key: ServicesSliceKey, saga: ServicesRepoSaga });
  //************** */
  //*********** */
  useInjectReducer({
    key: ServiceOrderSliceKey,
    reducer: ServiceOrderReducer
  });
  useInjectSaga({ key: ServiceOrderSliceKey, saga: ServiceOrderRepoSaga });
  //************** */

  /******* */
  useInjectReducer({
    key: PlotForSalesSliceKey,
    reducer: PlotForSalesReducer
  });
  useInjectSaga({ key: PlotForSalesSliceKey, saga: PlotForSaleRepoSaga });
  /******* */
  /******* */
  useInjectReducer({
    key: ConstructionLeadSliceKey,
    reducer: ConstructionLeadReducer
  });
  useInjectSaga({ key: ConstructionLeadSliceKey, saga: ConstructionLeadRepoSaga });
  /******* */
  /******* */
  useInjectReducer({
    key: SupportSliceKey,
    reducer: SupportReducer
  });
  useInjectSaga({ key: SupportSliceKey, saga: SupportRepoSaga });
  /******* */
  /******* */
  useInjectReducer({
    key: ImageUploadSliceKey,
    reducer: ImageUploadReducer
  });
  useInjectSaga({ key: ImageUploadSliceKey, saga: ImageUploadRepoSaga });
  /******* */
  /******* */
  useInjectReducer({
    key: BlogSliceKey,
    reducer: BlogReducer
  });
  useInjectSaga({ key: BlogSliceKey, saga: BlogRepoSaga });
  //************ */
  /******* */
  useInjectReducer({
    key: PlotToBuySliceKey,
    reducer: PlotToBuyReducer
  });
  useInjectSaga({ key: PlotToBuySliceKey, saga: PlotToBuyRepoSaga });
  //************ */
  /******* */
  useInjectReducer({
    key: PropertyLeadsSliceKey,
    reducer: PropertyLeadsReducer
  });
  useInjectSaga({ key: PropertyLeadsSliceKey, saga: PropertyLeadsRepoSaga });
  //************ */
  /******* */
  useInjectReducer({
    key: DashboardSliceKey,
    reducer: DashboardReducer
  });
  useInjectSaga({ key: DashboardSliceKey, saga: DashboardRepoSaga });
  //************ */
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
