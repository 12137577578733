import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import CustomButton from 'components/Common/CustomButton';
import ModalTemplate from 'components/Common/ModalTemplate/Index';
import NewMultiFileUpload from 'components/Common/MultiFileUpload/NewMultiFileUpload';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OtpInputComponent from './OtpInputComponent';
import { selectAdminOtp, selectButtonLoading, selectModalOpen, selectOrderCompleteFormData, selectReGenerateOtp } from './selector';
import { actions } from './slice';
import { AdminOrderSubmissionTypeEnum } from './types';
import IsLoading from 'components/Common/IsLoading';

interface props {
  title: string;
  content: string;
  selectedId: string | null;
}
const UpdateOrderModal: React.FC<props> = ({ title, content, selectedId }) => {
  const form = useSelector(selectOrderCompleteFormData);
  const buttonLoading = useSelector(selectButtonLoading);
  const adminOtp = useSelector(selectAdminOtp);
  const reGenerateOtp = useSelector(selectReGenerateOtp);
  const show = useSelector(selectModalOpen);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(actions.clearForm());
    dispatch(actions.toggleModalOpen(false));
    dispatch(actions.doGetList());
  };

  const handleSubmit = (id: string, type: number) => {
    dispatch(actions.updateUpdateOrderFormValue({ key: 'submissionType', value: type }));
    dispatch(
      actions.doCompleteOrder({
        id: id,
        callback() {
          dispatch(actions.clearForm());
          dispatch(actions.toggleModalOpen(false));
          dispatch(actions.doGetList());
        }
      })
    );
  };
  const handleReGenerate = (id: string) => {
    dispatch(actions.doRe_GenerateUserOtp({ id: id }));
  };
  const handleAdminOtp = (id: string) => {
    dispatch(actions.doGenerateAdminOtp({ id: id }));
  };

  const handleChangeUploadField = (val: any) => {
    dispatch(
      actions.updateImagesFormValue({
        value: val
      })
    );
  };

  useEffect(() => {
    return () => {};
  }, [show]);
  const handleChange = (name: string, value: any) => {
    dispatch(actions.updateUpdateOrderFormValue({ key: name, value: value }));
  };
  return (
    <>
      <ModalTemplate open={show} title={title} handleClose={onClose}>
        <HeadingTypo>{content}</HeadingTypo>
        {buttonLoading ? (
          <IsLoading />
        ) : (
          <>
            <Box sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <NewMultiFileUpload
                  data={form?.images}
                  handleUploadImage={(val: any) => {
                    handleChangeUploadField(val);
                  }}
                />
              </Grid>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-evenly">
                  <OtpInputComponent value={form?.otp} handleChange={handleChange} name={'otp'} />
                </Stack>
              </Grid>
              <Grid item xs={12} mt={3}>
                <Stack direction="row" justifyContent="space-evenly">
                  <CustomButton
                    loading={reGenerateOtp}
                    handleClick={() => handleReGenerate(selectedId as string)}
                    title={'Re-generate OTP'}
                    variant="outlined"
                  />
                  <CustomButton
                    loading={adminOtp}
                    handleClick={() => handleAdminOtp(selectedId as string)}
                    title={'Admin Otp'}
                    mainSx={{ ml: 10 }}
                    variant="outlined"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Stack direction="row" justifyContent="space-between">
                  <CustomButton
                    loading={form?.submissionType == AdminOrderSubmissionTypeEnum.FORCE_SUBMIT && buttonLoading}
                    handleClick={() => handleSubmit(selectedId as string, AdminOrderSubmissionTypeEnum.FORCE_SUBMIT)}
                    title={'Force Submit'}
                  />
                  <CustomButton
                    loading={form?.submissionType == AdminOrderSubmissionTypeEnum.SUBMIT && buttonLoading}
                    handleClick={() => handleSubmit(selectedId as string, AdminOrderSubmissionTypeEnum.SUBMIT)}
                    title={'Submit'}
                    mainSx={{ ml: 10 }}
                  />
                </Stack>
              </Grid>
            </Box>
          </>
        )}
      </ModalTemplate>
    </>
  );
};

export default UpdateOrderModal;

const HeadingTypo = styled(Typography)({
  fontSize: 20,
  fontWeight: 400,
  lineHeight: '16.94px',
  color: '#000'
});
