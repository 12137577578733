import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IData } from './types';
import { initialState } from './types/initialState';

export const useClinicSlice = createSlice({
  name: 'imageUpload',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    doGetList: (state) => {
      state.loading = true;
    },
    setList: (state, action: PayloadAction<Array<IData>>) => {
      state.List = action.payload;
    },
    uploadImage: (
      state,
      action: PayloadAction<{
        fileName: string;
        callback: () => void;
      }>
    ) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
