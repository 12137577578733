import { DateTime } from 'luxon';

const DateFormat = (data: any, type?: 'time' | 'with_time') => {
  let formatData;
  if (type == 'time') {
    formatData = DateTime.fromISO(data).toFormat('HH:mm');
  } else if (type == 'with_time') {
    formatData = DateTime.fromISO(data).toFormat('yyyy.LL.dd ,HH:mm');
  } else {
    formatData = DateTime.fromISO(data).toFormat('yyyy.LL.dd');
  }
  return formatData;
};

export default DateFormat;
