import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { IData, StatusFilterEnum } from './types';

export const useClinicSlice = createSlice({
  name: 'supportState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setOrderDir: (state, action: PayloadAction<string>) => {
      state.orderDir = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<StatusFilterEnum>) => {
      state.status = action.payload;
    },
    doUpdateStatus: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},

    doGetList: (state) => {
      state.loading = true;
    },
    setList: (state, action: PayloadAction<Array<IData>>) => {
      state.List = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
