import React from 'react';
import OtpInput from 'react-otp-input';

interface Props {
  value: string;
  handleChange: (name: string, value: any) => void;
  name: string;
}

const OtpInputComponent: React.FC<Props> = ({ handleChange, name, value }) => {
  return (
    <OtpInput
      value={value}
      onChange={(value) => handleChange(name, value)}
      numInputs={4}
      renderSeparator={<span> &nbsp; &nbsp; </span>}
      renderInput={(props) => <input {...props} name={name} />}
      shouldAutoFocus={true}
      inputStyle={inputStyling}
    />
  );
};
export default OtpInputComponent;

const inputStyling = {
  border: '1px solid #CFD3DB',
  borderRadius: '8px',
  width: '54px',
  height: '54px',
  fontSize: '14px',
  color: '#000',
  fontWeight: 500,
  caretColor: '#000'
};
