export interface IData {
  _id: string;
  ticketId: string;
  mobileNumber: string;
  name: string;
  email: string;
  status: StatusFilterEnum;
  message: string;
  propertyNo: string;
  propertyId: string;
  preferredContactDate: string;
  contactStartTime: string;
  contactEndTime: string;
  createdAt: string;
  updatedAt: string;
}
export enum StatusFilterEnum {
  ALL = 0,
  OPEN = 1,
  CLOSE = 2
}
export interface PropertyLeadsState {
  List: IData[];
  loading: boolean;
  buttonLoading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  orderBy: string;
  orderDir: string;
  status: StatusFilterEnum;
}

export type InitialState = PropertyLeadsState;
