import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { IData } from './types';
import { initialState } from './types/initialState';

export const useClinicSlice = createSlice({
  name: 'blogState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setIsFileUpload: (state, action: PayloadAction<{ blogFile: boolean; thumbFile: boolean }>) => {
      state.isFileUpload.blogFile = action.payload.blogFile;
      state.isFileUpload.thumbFile = action.payload.thumbFile;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setOrderDir: (state, action: PayloadAction<string>) => {
      state.orderDir = action.payload;
    },
    doDelete: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form._id = '';
      state.form.featured = false;
      state.form.file = '';
      state.form.longDescription = '';
      state.form.title = '';
      state.form.metaDescription = '';
      state.form.metaTitle = '';
      state.form.shortDescription = '';
      state.form.thumbImage = '';
      state.form.seoTitle = '';
      state.form.isActive = 1;
    },

    doUpdateStatus: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    doCreate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},
    doUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    doGetList: (state) => {
      state.loading = true;
    },
    setList: (state, action: PayloadAction<Array<IData>>) => {
      state.List = action.payload;
    },
    setDataInForm: (state, action: PayloadAction<IData>) => {
      state.form = action.payload;
    },
    doGetById: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      state.loading = true;
    },

    getSignedUrl: (
      state,
      action: PayloadAction<{
        fileName: string;
        callback: (url: string, prefix: string) => void;
      }>
    ) => {},
    UploadImage: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>
    ) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
