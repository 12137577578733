import { ImageUploadState } from '.';

export const initialState: ImageUploadState = {
  List: [],
  loading: false,
  buttonLoading: false,
  pageNo: 1,
  pageSize: 12,
  totalRow: 0,
  hasMore: false
};
