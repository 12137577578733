import { CameraOutlined, DeleteTwoTone, UploadOutlined } from '@ant-design/icons';
import { Box, Button, FormLabel, Grid, IconButton, LinearProgress, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { actions } from 'pages/ServiceCreation/slice';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { path } from 'utilsNew/Api';

type Props = {
  data?: [] | any;
  handleDeleteFile?: () => void | any;
  handleUploadImage?: any;
};

const Index: React.FC<Props> = ({ data, handleDeleteFile, handleUploadImage }) => {
  const theme = useTheme();

  const [uploadFiles, setUploadFiles] = useState<any[]>([]);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();

  const handleFileChange = async (evt: any) => {
    const file: File[] = evt.target.files;
    if (file && file?.length > 0) {
      const fileList = uploadFiles;

      for (let index = 0; index < file.length; index++) {
        const element = file[index];
        fileList.push({
          name: element.name,
          url: '',
          prefix: '',
          data: element,
          inprogress: false,
          completed: false
        });
      }
      setUploadFiles(fileList);
      for (let index = 0; index < fileList.length; index++) {
        const element = fileList[index];
        if (!element.completed && !element.inprogress) {
          const data = await getSignedUrl(element.name);
          fileList[index].url = data.url;
          fileList[index].prefix = data.prefix;
        }
      }
      setUploadFiles(fileList);
      for (let index = 0; index < fileList.length; index++) {
        const element = fileList[index];
        if (!element.completed && !element.inprogress) {
          fileList[index].inprogress = true;
          setUploadFiles(fileList);
          setProgress(0);
          await uploadFile(element.url, element.data);
          setProgress(0);
          fileList[index].completed = true;
          fileList[index].inprogress = false;
          setUploadFiles(fileList);
          handleUploadImage(fileList[index].prefix);
        }
      }
    }
  };
  async function getSignedUrl(fileName: string): Promise<{ url: string; prefix: string }> {
    return new Promise((resolve, reject) => {
      try {
        dispatch(
          actions.getSignedUrl({
            fileName: fileName,
            callback: (url: string, prefix: string) => {
              console.log('sss', { url, prefix });
              resolve({ url, prefix });
            }
          })
        );
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  }
  async function uploadFile(s3SignedUrl: string, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', s3SignedUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.setRequestHeader('x-amz-acl', 'public-read');
        xhr.upload.onprogress = (evt) => {
          console.log('onprogress');

          if (evt.lengthComputable) {
            console.log('onprogress1');
            var percentComplete = (evt.loaded / evt.total) * 100;
            setProgress(percentComplete);
          }
        };
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              console.log('File uploaded successfully');
              resolve();
            } else {
              console.error('Failed to upload file to S3');
              reject(new Error('Failed to upload file to S3'));
            }
          }
        };
        xhr.send(file);
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  }
  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid xs={12}>
            {uploadFiles.filter((x) => x.inprogress).length > 0 && (
              <Box my={5}>
                <Typography>{uploadFiles.filter((x) => x.inprogress)[0].name}</Typography>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
            )}
          </Grid>
        </Grid>
        <FormLabel
          htmlFor="multiple-image"
          sx={{
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer'
          }}
        >
          <Button variant="outlined" color="primary" startIcon={<UploadOutlined />} sx={{ mt: 1, textTransform: 'none' }}>
            Click to Upload
          </Button>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
              width: '100%',
              height: '100%',
              opacity: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Stack spacing={0.5} alignItems="center">
              <CameraOutlined style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
              <Typography sx={{ color: 'secondary.lighter' }}>Upload</Typography>
            </Stack>
          </Box>
        </FormLabel>
        <input
          name="image"
          placeholder="Image"
          type="file"
          accept=".jpg,.png,.jpeg"
          id="multiple-image"
          onChange={handleFileChange}
          draggable="true"
          multiple
          style={{ visibility: 'hidden' }}
        />

        <Grid container spacing={1}>
          {data?.map((ele: string, index: number) => {
            return (
              <Grid item xs={6} md={4} lg={1.5} mt={3} key={index} sx={{ position: 'relative' }}>
                <img
                  src={`${path()}${ele}`}
                  alt={ele}
                  //@ts-ignore
                  style={imgStyle}
                />
                <>
                  {ele?.length > 0 && (
                    <Box>
                      {/* @ts-ignore */}
                      <Tooltip sx={iconStyle} title="Delete" onClick={() => handleDeleteFile(index)}>
                        <IconButton>
                          <DeleteTwoTone twoToneColor={theme.palette.primary.main} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default Index;
const iconStyle = { position: 'absolute', right: 0, bottom: 2 };
const imgStyle = { width: '100%', height: '75px', objectFit: 'cover', borderRadius: '5px' };
