import { BlogState } from '.';

export const initialState: BlogState = {
  List: [],
  loading: false,
  buttonLoading: false,
  isFileUpload: {
    thumbFile: false,
    blogFile: false
  },
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  form: {
    _id: '',
    title: '',
    seoTitle: '',
    thumbImage: '',
    file: '',
    metaTitle: '',
    metaDescription: '',
    shortDescription: '',
    longDescription: '',
    featured: false,
    isActive: 1,
    createdAt: '',
    updatedAt: ''
  }
};
