import { DownloadOutlined, RightOutlined } from '@ant-design/icons';
import { Box, Button, CircularProgress } from '@mui/material';

import AnimateButton from 'components/@extended/AnimateButton';
import React from 'react';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  handleClick: any;
  title: string;
  sx?: any;
  mainSx?: any;
  loadingSize?: number;
  icon?: string;
  iconSx?: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
  iconColor?: boolean;
  variant?: any;
  isEndIcon?: boolean;
  isStartIcon?: boolean;
}

const Index: React.FC<Props> = ({
  loading,
  disabled,
  handleClick,
  title,
  sx,
  loadingSize,
  icon,
  iconSx,
  type,
  variant,
  mainSx,
  isStartIcon,
  isEndIcon
}) => {
  return (
    <Box sx={mainSx}>
      <AnimateButton>
        <Button
          startIcon={isStartIcon ? <DownloadOutlined /> : ''}
          endIcon={isEndIcon ? <RightOutlined /> : ''}
          variant={variant ? variant : 'contained'}
          fullWidth
          disabled={disabled ? disabled : loading}
          type={type ? type : 'button'}
          color="primary"
          onClick={handleClick}
          sx={sx}
        >
          {loading ? (
            <CircularProgress
              sx={{
                width: loadingSize ? `${loadingSize}px !important` : '25px !important',
                height: loadingSize ? `${loadingSize}px !important` : '25px !important',
                color: '#fff'
              }}
            />
          ) : (
            <>
              {title}
              {icon && <img src={icon} alt="icon" style={iconSx ? iconSx : iconStyle} />}
            </>
          )}
        </Button>
      </AnimateButton>
    </Box>
  );
};

export default Index;
const iconStyle = {
  width: 14,
  marginLeft: 21
};
