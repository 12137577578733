export enum StatusFilterEnum {
  ALL = 0,
  OPEN = 1,
  CLOSE = 2,
  PENDING = 3
}
export interface IData {
  _id: string;
  ticketId: string;
  name: string;
  noOfFloors: number;
  noOfBhk: number;
  listingDoneBy: ListingDoneByTypeEnum;
  isActive: boolean;
  images: string[];
  createdAt: string;
  updatedAt: string;
  status: StatusFilterEnum;
}
export enum ListingDoneByTypeEnum {
  OWNER = 1,
  DEALER = 2
}
export interface ConstructionLeadState {
  List: IData[];
  loading: boolean;
  buttonLoading: boolean;
  search: string;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  orderBy: string;
  orderDir: string;
  endDate: string;
  startDate: string;
  status: StatusFilterEnum;
}

export type InitialState = ConstructionLeadState;
