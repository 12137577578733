import axios from 'axios';

let url = '';
let statucUrl = '';
let google_map_api = '';
url = process.env.REACT_APP_API || '';
statucUrl = process.env.REACT_APP_ASSETSURL || '';
google_map_api = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const path = () => {
  return statucUrl;
};
export default axios.create({
  baseURL: url
});
export const apiPath = () => {
  return url;
};
export const GOOGLE_MAPS_API_KEY = google_map_api;
