import { AxiosResponse } from 'axios';
import CustomSnackbar from 'components/Common/CustomSnackbar';
import { servicesValidation } from 'components/Common/Validations';
import CatchError from 'hooks/CatchError';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { get, patch, post, uploadImg } from 'utilsNew/request';
import { selectForm } from './selector';
import { actions } from './slice';
import { IServices } from './types';

export function* doGetListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(get, `admin/service/list`);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* doGetByIdRequest(action: {
  payload: {
    id: string;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(get, `admin/service/get/${action.payload.id}`);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setDataInForm(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* doUpdateRequest(action: {
  payload: {
    callback: any;
  };
}) {
  yield delay(500);
  try {
    const form: IServices = yield select(selectForm);

    const isValid = servicesValidation(form);
    if (!isValid) {
      return;
    }

    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(post, `admin/service/update/${form._id}`, form);
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    CustomSnackbar(response.data.message, 'success');
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchError(error);
  }
}
export function* doUpdateStatusRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(patch, `admin/service/status/update/${action?.payload?.id}`, {});
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchError(error);
  }
}

export function* uploadDocumentRequest(action: { payload: { fileName: string; callback: any } }) {
  try {
    const response: AxiosResponse = yield call(post, `/admin/service/signed-url`, {
      fileName: action.payload.fileName
    });
    if (!response.status) {
      CustomSnackbar(response.data.message, 'error');
    }
    yield call(action?.payload?.callback(response.data.data.signedUrl, response.data.data.fileNameWithPrefix));
  } catch (error: any) {
    CatchError(error);
  }
}
export function* UploadImageRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(uploadImg, action.payload.signedUrl, action.payload.data, action.payload.result);
    if (!response.status) {
      CustomSnackbar(response.data.message, 'error');
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setIsFileUpload(false));
    CatchError(error);
  }
}
export function* ServicesRepoSaga() {
  //******file upload */
  yield takeEvery(actions.getSignedUrl, uploadDocumentRequest);
  yield takeEvery(actions.UploadImage, UploadImageRequest);

  ///////
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetById, doGetByIdRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doUpdateStatus, doUpdateStatusRequest);
}
