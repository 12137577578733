import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { RootState } from 'newTypes';

const selectDomain = (state: RootState) => {
  if (state && state.plotToBuyState) {
    return state.plotToBuyState;
  } else {
    return initialState;
  }
};

export const selectLoading = createSelector([selectDomain], (state) => state.loading);
export const selectButtonLoading = createSelector([selectDomain], (state) => state.buttonLoading);
export const selectSearch = createSelector([selectDomain], (state) => state.search);
export const selectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const selectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const selectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const selectOrderBy = createSelector([selectDomain], (state) => state.orderBy);
export const selectOrderDir = createSelector([selectDomain], (state) => state.orderDir);
export const selectStatusFilter = createSelector([selectDomain], (state) => state.status);
export const selectStartDate = createSelector([selectDomain], (state) => state.startDate);
export const selectEndDate = createSelector([selectDomain], (state) => state.endDate);
export const selectList = createSelector([selectDomain], (state) => state.List);
export const selectForm = createSelector([selectDomain], (state) => state.form);
