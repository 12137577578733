import { ServicesState } from '.';

export const initialState: ServicesState = {
  form: {
    _id: '',
    title: '',
    description: '',
    highlights: '',
    fee: 0,
    isActive: false,
    images: [],
    icon: '',
    imagesForm: {
      _id: '',
      file: ''
    }
  },
  List: [],
  loading: false,
  buttonLoading: false,
  isFileUpload: false
};
