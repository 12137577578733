import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type Props = {
  value: any;
  onChange: any;
  name: string;
  data: any;
  sx?: any;
  handleOnBlur?: any;
  disabled?: boolean;
  isShowLabel?: boolean;
  label?: string;
};

const Index: React.FC<Props> = ({ value, onChange, name, data, sx, handleOnBlur, disabled, label, isShowLabel }) => {
  return (
    <>
      <FormControl fullWidth sx={sx}>
        <InputLabel id="demo-simple-select-label">{isShowLabel ? (label ? label : 'Status Filter') : ''}</InputLabel>
        <Select
          label={isShowLabel ? (label ? label : 'Status Filter') : ''}
          disabled={disabled ? disabled : false}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
          onBlur={handleOnBlur}
          name={name}
        >
          {data?.map((data: any) => (
            <MenuItem key={data?.id} value={data?.id}>
              {data?.item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default Index;
