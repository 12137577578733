import { Box, styled } from '@mui/material';

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    <ImgBox>
      {/* @ts-ignore */}
      <img src="/logo.png" alt="logo" style={ImgStyle} loading="lazy" />
    </ImgBox>
  );
};

export default LogoMain;
const ImgBox = styled(Box)({
  marginTop: 20
});
const ImgStyle = { marginTop: 10, width: 150, objectFit: 'cover' };
