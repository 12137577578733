import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { IServices } from './types';
import { set } from 'lodash';

export const useClinicSlice = createSlice({
  name: 'servicesState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setIsFileUpload: (state, action: PayloadAction<boolean>) => {
      state.isFileUpload = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form._id = '';
      state.form.description = '';
      state.form.fee = 0;
      state.form.highlights = '';
      state.form.title = '';
      state.form.icon = '';
      state.form.images = [];
      state.form.isActive = false;
    },

    doRemoveImages: (
      state,
      action: PayloadAction<{
        index: number;
      }>
    ) => {
      state.form.images[action.payload.index].isDeleted = true;
    },
    doUpdateStatus: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    doUpdate: (
      state,
      action: PayloadAction<{
        callback: () => void;
      }>
    ) => {},

    doGetList: (state) => {
      state.loading = true;
    },
    setList: (state, action: PayloadAction<Array<IServices>>) => {
      state.List = action.payload;
    },
    setDataInForm: (state, action: PayloadAction<IServices>) => {
      state.form = action.payload;
    },
    doGetById: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      state.loading = true;
    },
    updateImagesFormUploadDocumentFieldsValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      state.form.images.push({ file: action.payload.value });
    },
    getSignedUrl: (
      state,
      action: PayloadAction<{
        fileName: string;
        callback: (url: string, prefix: string) => void;
      }>
    ) => {},
    UploadImage: (
      state,
      action: PayloadAction<{
        data: any;
        signedUrl: string;
        result: any;
        callback: () => void;
      }>
    ) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
