import { StatusFilterEnum, PropertyLeadsState } from '.';

export const initialState: PropertyLeadsState = {
  List: [],
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: StatusFilterEnum.ALL
};
