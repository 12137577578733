import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdminOrderSubmissionTypeEnum, IData, OrderStatusFilterEnum } from './types';
import { initialState } from './types/initialState';
import { set } from 'lodash';

export const useClinicSlice = createSlice({
  name: 'serviceOrder',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setOrderBy: (state, action: PayloadAction<string>) => {
      state.orderBy = action.payload;
    },
    setOrderDir: (state, action: PayloadAction<string>) => {
      state.orderDir = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string | null>) => {
      state.endDate = action.payload;
    },
    setStartDate: (state, action: PayloadAction<string | null>) => {
      state.startDate = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<OrderStatusFilterEnum>) => {
      state.status = action.payload;
    },

    doGetList: (state) => {},
    setList: (state, action: PayloadAction<Array<IData>>) => {
      state.List = action.payload;
    },
    doGetById: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      state.loading = true;
    },

    doChangeStatus: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {},

    //// order update

    toggleModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modalOpen = action.payload;
    },
    setReGenerateOtp: (state, action: PayloadAction<boolean>) => {
      state.reGenerateOtp = action.payload;
    },
    setAdminOtp: (state, action: PayloadAction<boolean>) => {
      state.adminOtp = action.payload;
    },

    doGenerateUserOtp: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {
      state.buttonLoading = true;
    },
    doRe_GenerateUserOtp: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {},
    doGenerateAdminOtp: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {},
    updateUpdateOrderFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `orderCompleteFormData.${action.payload.key}`, action.payload.value);
    },
    updateImagesFormValue: (state, action: PayloadAction<{ value: any }>) => {
      state.orderCompleteFormData.images.push(action.payload.value);
    },
    clearForm: (state) => {
      state.orderCompleteFormData.otp = '';
      state.orderCompleteFormData.images = [];
      state.orderCompleteFormData.submissionType = AdminOrderSubmissionTypeEnum.NULL;
    },
    doCompleteOrder: (
      state,
      action: PayloadAction<{
        id: string;
        callback: () => void;
      }>
    ) => {}
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
