import { IData } from 'pages/Blog/types';
import { IProperty } from 'pages/PropertyListing/types';
import { IServices } from 'pages/ServiceCreation/types';
import { ILogin, IPassword } from 'sections/auth/redux/types';
import { emailRegex } from 'utilsNew/constants/Email';
import { passwordRegex } from 'utilsNew/constants/Password';
import CustomSnackbar from '../CustomSnackbar';
import { IOrderComplete } from 'pages/ServiceOrder/types';

export const loginValidation = (form: ILogin) => {
  if (form?.email?.length == 0) {
    CustomSnackbar('Please enter  email', 'error');

    return false;
  }
  if (form?.email?.length > 0 && !emailRegex.test(form?.email)) {
    CustomSnackbar('Please enter valid email', 'error');

    return false;
  }
  if (form?.password?.length == 0) {
    CustomSnackbar('Please enter password', 'error');
    return false;
  }
  return true;
};
export const updatePasswordValidation = (form: IPassword) => {
  if (form?.password?.length == 0) {
    CustomSnackbar('Please enter password', 'error');

    return false;
  }
  if (form?.password?.length > 0 && !passwordRegex.test(form?.password)) {
    CustomSnackbar('Please enter valid', 'error');

    return false;
  }
  if (form?.confirmPassword?.length == 0) {
    CustomSnackbar('Please enter confirm password', 'error');

    return false;
  }
  if (form?.confirmPassword?.length > 0 && !passwordRegex.test(form?.confirmPassword)) {
    CustomSnackbar('Please enter valid confirm password', 'error');

    return false;
  }
  if (form?.password !== form?.confirmPassword) {
    CustomSnackbar('password and confirm password must be the same', 'error');

    return false;
  }
  return true;
};
export const propertyValidation = (form: IProperty) => {
  if (form?.address?.length == 0) {
    CustomSnackbar('Please enter address', 'error');
    return false;
  }
  if (form?.areaOfPlot?.length == 0) {
    CustomSnackbar('Please enter area of plot', 'error');
    return false;
  }
  if (!form?.nearbyLocation || form?.nearbyLocation?.length == 0) {
    CustomSnackbar('Please enter near by location', 'error');
    return false;
  }
  if (form?.priceAreaWise?.length == 0) {
    CustomSnackbar('Please enter price area wise', 'error');
    return false;
  }
  if (form?.pricePerSqft?.length == 0) {
    CustomSnackbar('Please enter price per Sq ft', 'error');
    return false;
  }
  if (form?.projectSize?.length == 0) {
    CustomSnackbar('Please enter project size', 'error');
    return false;
  }
  if (form?.plotType?.length == 0) {
    CustomSnackbar('Please enter plot type', 'error');
    return false;
  }
  if (form?.amenities?.length == 0) {
    CustomSnackbar('Please enter amenities', 'error');
    return false;
  }
  if (form?.aboutBuilder?.length == 0) {
    CustomSnackbar('Please enter about builder', 'error');
    return false;
  }
  if (form?.locationHighlights?.length == 0) {
    CustomSnackbar('Please enter location highlights', 'error');
    return false;
  }
  if (form?.propertyRatings == 0) {
    CustomSnackbar('Please enter property ratings', 'error');
    return false;
  }
  if (form?.propertyFacing == 0) {
    CustomSnackbar('Please select property facing', 'error');
    return false;
  }
  if (form?.availabilityStatus == 0) {
    CustomSnackbar('Please select availability status', 'error');
    return false;
  }
  if (form?.approvals?.length == 0) {
    CustomSnackbar('Please select approvals', 'error');
    return false;
  }
  if (form?.faqs?.length == 0) {
    CustomSnackbar('Please fill faqs', 'error');
    return false;
  }
  if (form?.reviews?.length == 0) {
    CustomSnackbar('Please fill reviews', 'error');
    return false;
  }
  if (form?.brochure?.length == 0) {
    CustomSnackbar('Please upload brochure', 'error');
    return false;
  }

  const filterImageData = form?.images?.filter((element) => {
    return element.isDeleted === false || element.isDeleted === undefined;
  });
  if (filterImageData?.length == 0) {
    CustomSnackbar('Please upload images', 'error');
    return false;
  }
  if (filterImageData?.length < 2) {
    CustomSnackbar('Please upload minimum 2 images', 'error');
    return false;
  }
  return true;
};
export const servicesValidation = (form: IServices) => {
  if (form?.description?.length == 0) {
    CustomSnackbar('Please enter description', 'error');
    return false;
  }
  if (form?.highlights?.length == 0) {
    CustomSnackbar('Please enter highlights', 'error');
    return false;
  }
  if (form?.fee == 0) {
    CustomSnackbar('Please enter fee', 'error');
    return false;
  }
  const filterImageData = form?.images?.filter((element) => {
    return element.isDeleted === false || element.isDeleted === undefined;
  });
  if (filterImageData?.length == 0) {
    CustomSnackbar('Please upload images', 'error');
    return false;
  }
  if (filterImageData?.length < 2) {
    CustomSnackbar('Please upload minimum 2 images', 'error');
    return false;
  }
  return true;
};
export const blogValidation = (form: IData) => {
  if (form?.title?.length == 0) {
    CustomSnackbar('Please enter blog title', 'error');
    return false;
  }
  if (form?.shortDescription?.length == 0) {
    CustomSnackbar('Please enter short description', 'error');
    return false;
  }
  if (form?.longDescription?.length == 0) {
    CustomSnackbar('Please enter longDescription', 'error');
    return false;
  }
  if (form?.metaTitle?.length == 0) {
    CustomSnackbar('Please enter meta title', 'error');
    return false;
  }
  if (form?.metaDescription?.length == 0) {
    CustomSnackbar('Please enter meta description', 'error');
    return false;
  }
  if (form?.thumbImage?.length == 0) {
    CustomSnackbar('Please select thumbnail image', 'error');
    return false;
  }
  if (form?.file?.length == 0) {
    CustomSnackbar('Please select blog image', 'error');
    return false;
  }
  return true;
};
export const orderUpdateValidation = (form: IOrderComplete) => {
  if (form?.otp?.length == 0) {
    CustomSnackbar('Please enter otp', 'error');
    return false;
  }
  if (form?.images?.length == 0) {
    CustomSnackbar('Please upload images', 'error');
    return false;
  }
  if (form?.images?.length < 2) {
    CustomSnackbar('Please upload minimum 2 images', 'error');
    return false;
  }
  return true;
};
