import { AxiosResponse } from 'axios';
import CustomSnackbar from 'components/Common/CustomSnackbar';
import CatchError from 'hooks/CatchError';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { get, post } from 'utilsNew/request';
import { selectPageNo, selectPageSize } from './selector';
import { actions } from './slice';

export function* doGetListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const response: AxiosResponse = yield call(get, `admin/property/image/list?pageNo=${pageNo}&pageSize=${pageSize}`);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      CustomSnackbar(response.data.message, 'error');
      return;
    }
    yield put(actions.setList(response.data.data.results));
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.totalPages : 0));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchError(error);
  }
}
export function* uploadDocumentRequest(action: { payload: { fileName: string; callback: any } }) {
  try {
    const response: AxiosResponse = yield call(post, `admin/property/images/upload`, {
      file: action.payload.fileName
    });
    if (!response.status) {
      CustomSnackbar(response.data.message, 'error');
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchError(error);
  }
}

export function* ImageUploadRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.uploadImage, uploadDocumentRequest);
}
