import { PlotFacingTypeEnum, PlotTypeEnum, PropertyAvailabilityStatusEnum } from 'pages/PropertyListing/types';
import { AdminOrderSubmissionTypeEnum, OrderStatusFilterEnum, ServiceOrderState } from '.';

export const initialState: ServiceOrderState = {
  List: [],
  form: {
    _id: '',
    location: {
      lat: 26.963615051025762,
      lng: 75.7670243899338,
      type: 'Point',
      coordinates: []
    },

    amenities: [],
    brochure: '',
    aboutBuilder: '',
    approvals: [],
    locationHighlights: '',
    availabilityStatus: PropertyAvailabilityStatusEnum.OPEN,
    images: [],
    reviews: [],
    faqs: [],
    plotType: PlotTypeEnum.Null,
    reviewsForm: {
      review: '',
      _id: '',
      createdAt: '',
      updatedAt: ''
    },
    faqsForm: {
      question: '',
      answer: '',
      _id: '',
      createdAt: '',
      updatedAt: ''
    },
    createdAt: '',
    isActive: true,
    propertyFacing: PlotFacingTypeEnum.EAST,
    propertyRatings: 0,
    address: '',
    propertyNo: '',
    areaOfPlot: '',
    pricePerSqft: '',
    priceAreaWise: '',
    projectSize: '',
    nearbyLocation: '',
    updatedAt: ''
  },
  loading: false,
  buttonLoading: false,
  search: '',
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  orderBy: '',
  orderDir: '',
  status: OrderStatusFilterEnum.ALL,
  endDate: '',
  startDate: '',
  orderCompleteFormData: {
    otp: '',
    images: [],
    submissionType: AdminOrderSubmissionTypeEnum.NULL
  },
  modalOpen: false,
  reGenerateOtp: false,
  adminOtp: false
};
