import { Box, styled } from '@mui/system';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    <ImgBox>
      {/* @ts-ignore */}
      <img src="/ploatpropfav.png" alt="logo" style={ImgStyle} loading="lazy" />
    </ImgBox>
  );
};

export default LogoIcon;
const ImgBox = styled(Box)({
  marginTop: 20
});
const ImgStyle = { width: 30, objectFit: 'cover' };
