import dashboard from './dashboard';
import { NavItemType } from 'types/menu';
import custom from './custom';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (): { items: NavItemType[] } => {
  const menuItems: { items: NavItemType[] } = {
    items: [dashboard, custom]
  };

  return menuItems;
};

export default menuItems;
